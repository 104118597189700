<template>
<div>
<div class="footer">
<div class="footer-zone">
            <div class="heading">
                Search time zone by Region / Country
            </div>
            <div class="tmz">
                <router-link :to="{path:'/africa/'}">Africa</router-link>
                <router-link :to="{path:'/america/'}"> America</router-link>
                <router-link :to="{path:'/antarctica/'}">Antarctica</router-link>
                <router-link :to="{path:'/arctic/'}">Arctic</router-link>
                <router-link :to="{path:'/asia/'}">Asia</router-link>
                <router-link :to="{path:'/atlantic/'}">Atlantic</router-link>
                <router-link :to="{path:'/australia/'}">Australia</router-link>
                <router-link :to="{path:'/europe/'}">Europe</router-link>
                <router-link :to="{path:'/indian/'}">Indian</router-link>
                <router-link :to="{path:'/pacific/'}">Pacific</router-link>
                <router-link :to="{path:'/utc/'}">UTC</router-link>
            </div>
        </div>
        <div class="footer-letters">
            <div class="heading">
                Search time zone by City
            </div>

            <div class="letters">
                <a :href="$router.resolve({name: 'search', params: {q: a}}).href" v-for="a in abc" v-bind:key="a">{{a}}</a>
                <!-- <router-link v-for="a in abc" :to="{path:'/search/'+a}" v-bind:key="a">{{a}}</router-link> -->
            </div>
                <!-- <div class="flex flex-grow justify-center">
                <div id="waldo-tag-12333"></div>
               </div> -->
            
            <footer class="mt-16 border-t-4 border-black">
            <hr/>
            <div class="max-w-screen-xl px-4 py-4 mx-auto space-y-4 overflow-hidden sm:px-6 lg:px-8 ">
                <nav class="flex flex-wrap justify-center -mx-5 divide-x divide-gray-500">
                    <div class="px-5">
                        <a :href="$router.resolve({path: '/privacy/'}).href" class="tracking-wider text-3xl font-semibold leading-6 text-gray-800 hover:text-gray-700">
                            Privacy Policy
                        </a>
                    </div>
                </nav>
            </div>
        </footer>   
        </div>
        </div>
            
</div>
</template>

<script>

import '../../src/output.css'

export default {
    components: {
    },
    data() {
        return {
            abc:["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
        }
    },
    watch: {
        '$router.params': {
            handler(newValue, oldValue) {
                // console.log(3)
                // setTimeout(d=>{
                //     waldo.refreshTag("waldo-tag-12333")
                //     console.log(3)
                // },1000);
                
            }
        }
    },
    created() {
        // console.log(3)
                // setTimeout(d=>{
                //     waldo.refreshTag("waldo-tag-12333")
                //     console.log(3)
                // },1000);
    },
    methods: {
        
    }
}
</script>
